import { connect } from 'react-redux';
import { List } from 'immutable';

import {
  loadPlannerBank,
  updatePlannerBank,
  createPlannerBank,
  deletePlannerBank,
  plannerBankFormDestroy,
} from '../../../../../state/modules/plannerBanks/actions';

import {
  getImageUploadURL,
  getImageSignedURL,
  uploadImage,
  createMediaImage,
  setActiveImage,
} from '../../../../../state/modules/media/actions';

import PlannerBankForm from '../components/PlannerBankForm';
import Format from '../../../../../lib/Format';

const inputs = List(['name', 'site_url', 'logo', 'status']);

const mapStateToProps = (state, ownProps) => {
  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['plannerBanks', ownProps.id, val], '');
    if (['logo'].includes(val)) {
      errors['logo'] = state.plannerBanks.getIn([
        'form',
        'errors',
        val + '_id',
      ]);
    }
    if (state.plannerBanks.getIn(['form', 'errors', val])) {
      errors[val] = state.plannerBanks.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach((x) => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  const logo = data.logo;
  const size = 60;

  const imagePath = Format.buildImagePath('ratio', size, size, logo);

  const imageUrl = state.media.getIn(['images', imagePath]) ?? null;

  return {
    size,
    imagePath,
    imageUrl,
    isLoading: state.plannerBanks.get('isLoading'),
    isImageUploadingLoading: state.media.get('isImageUploading'),
    isImageSignedUrlLoading: state.media.get('isImageSignedUrlLoading'),
    isSubmitting: state.plannerBanks.getIn(['form', 'isSubmitting']),
    success: state.plannerBanks.getIn(['form', 'success']),
    created_id: state.plannerBanks.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(
      ['plannerBanks', ownProps.id, 'created_at'],
      null
    ),
    updatedAt: state.entities.getIn(
      ['plannerBanks', ownProps.id, 'updated_at'],
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadPlannerBank(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updatePlannerBank(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createPlannerBank(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deletePlannerBank(id, cb));
    },
    destroyForm: (formState) => {
      dispatch(plannerBankFormDestroy(formState));
    },
    getUploadUrl: (extension, directory, cb) => {
      dispatch(getImageUploadURL(extension, directory, cb));
    },
    upload: (path, file, cb) => {
      dispatch(uploadImage(path, file, cb));
    },
    createImage: (data, cb) => {
      dispatch(createMediaImage(data, cb));
    },
    getSignedUrl: (path, cb) => {
      dispatch(getImageSignedURL(path, cb));
    },
    setActiveImage: (imageUrl, imagePath) => {
      dispatch(setActiveImage(imageUrl, imagePath));
    },
  };
};

const PlannerBankFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerBankForm);

export default PlannerBankFormContainer;
