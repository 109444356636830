import { connect } from 'react-redux';
import { List } from 'immutable';
import moment from 'moment';

import {
  loadPlannerDocument,
  updatePlannerDocument,
  createPlannerDocuments,
  deletePlannerDocument,
  plannerDocumentsFormDestroy,
} from '../../../../../state/modules/plannerDocuments/actions';

import PlannerDocumentForm from '../components/PlannerDocumentForm';
import Config from '../../../../../Config';

const inputs = List([
  'planner',
  'profile',
  'file_name',
  'user_file_name',
  'file_extension',
  'file_directory',
  'file_bucket',
  'category_document',
  'document_type',
  'note',
  'section',
  'size_bytes',
  'uploaded_at',
  'status',
]);

const mapStateToProps = (state, ownProps) => {
  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(
      ['plannerDocuments', ownProps.id, val],
      ''
    );
    if (['planner', 'profile'].includes(val)) {
      errors[val] = state.plannerDocuments.getIn([
        'form',
        'errors',
        val + '_id',
      ]);
    } else {
      errors[val] = state.plannerDocuments.getIn(['form', 'errors', val]);
    }
  }

  // change select number values to strings to work with ant design select inputs
  Object.keys(data).forEach((x) => {
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }
  });

  data['uploaded_at'] = data['uploaded_at']
    ? moment(data['uploaded_at'], Config.get('API_DATETIME_FORMAT'))
    : moment();

  data['note'] = data['note'] ?? '';

  return {
    isLoading: state.plannerDocuments.get('isLoading'),
    isSubmitting: state.plannerDocuments.getIn(['form', 'isSubmitting']),
    success: state.plannerDocuments.getIn(['form', 'success']),
    created_id: state.plannerDocuments.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(
      ['plannerDocuments', ownProps.id, 'created_at'],
      null
    ),
    updatedAt: state.entities.getIn(
      ['plannerDocuments', ownProps.id, 'updated_at'],
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadPlannerDocument(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updatePlannerDocument(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createPlannerDocuments(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deletePlannerDocument(id, cb));
    },
    destroyForm: (formState) => {
      dispatch(plannerDocumentsFormDestroy(formState));
    },
  };
};

const PlannerDocumentFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerDocumentForm);

export default PlannerDocumentFormContainer;
