import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import {
  Affix,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Spin,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

import UserInput from '../../users/containers/UserInputContainer';
import CategoryDocumentInput from './CategoryDocumentInput';
import DocumentTypeInput from './DocumentTypeInput';
import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import { pathTo } from '../../../Routes';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import Config from '../../../../../Config';
import SectionInput from './SectionInput';

const PlannerDocumentForm = ({
  id,
  data,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  created_id,
  ...props
}) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [form] = Form.useForm();

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  };

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);
  useEffect(() => {
    if (id && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      dataObj['uploaded_at'] = dataObj['uploaded_at']
        ? moment(dataObj['uploaded_at'], Config.get('API_DATETIME_FORMAT'))
        : moment();
      form.setFieldsValue(dataObj);
    }
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('PlannerDocumentEditScreen', { id: created_id }));
    }
    return () => {
      destroyForm();
    };
  }, [created_id, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `PlannerDocumentForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {
      if (input in values) {
        if (['profile'].includes(input)) {
          payload['user_profile_id'] = values[input];
        }
        // transform entity refs to IDs
        else if (['planner'].includes(input)) {
          payload[input + '_id'] = values[input] ? values[input] : null;
        } else if (['uploaded_at'].includes(input)) {
          payload[input] = Format.date(
            values[input],
            Config.get('API_DATETIME_FORMAT')
          );
        } else if (['note'].includes(input)) {
          if (values[input].trim().length !== 0) {
            payload[input] = values[input];
          }
        } else {
          payload[input] = values[input];
        }
      }
    }

    if (id) {
      // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else {
      // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `PlannerDocumentForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `PlannerDocumentForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <div className="medium-form">
            <Form
              name="medium_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              validateTrigger="onSubmit"
              {...layout.main}
            >
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={
                      id
                        ? t('planner_documents_edit_title')
                        : t('planner_documents_add_title')
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    <div className="form-group">
                      <Form.Item
                        name="planner"
                        label={t('planner_documents_planner_id')}
                      >
                        <Input autoFocus disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <UserInput
                      form={form}
                      name="profile"
                      label={t('planner_documents_profile')}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                      disabled={isLoading || isSubmitting}
                    />

                    <CategoryDocumentInput
                      name={'category_document'}
                      label={t('planner_documents_category_document')}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                      disabled={isLoading || isSubmitting}
                    />

                    <DocumentTypeInput
                      name={'document_type'}
                      label={t('planner_documents_document_type')}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                      disabled={isLoading || isSubmitting}
                    />

                    <SectionInput
                      name="section"
                      label={t('planner_documents_section')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="file_name"
                        label={t('planner_documents_file_name')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            type: 'string',
                            min: 1,
                            max: 120,
                            message: t('feedback_validation_char_range', {
                              min: 1,
                              max: 120,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="user_file_name"
                        label={t('planner_documents_user_file_name')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            type: 'string',
                            min: 1,
                            max: 120,
                            message: t('feedback_validation_char_range', {
                              min: 1,
                              max: 120,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="file_extension"
                        label={t('planner_documents_file_extension')}
                        rules={[
                          {
                            type: 'string',
                            min: 0,
                            max: 20,
                            message: t('feedback_validation_char_range', {
                              min: 0,
                              max: 20,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="file_directory"
                        label={t('planner_documents_file_directory')}
                        rules={[
                          {
                            type: 'string',
                            min: 0,
                            max: 120,
                            message: t('feedback_validation_char_range', {
                              min: 0,
                              max: 120,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="file_bucket"
                        label={t('planner_documents_file_bucket')}
                        rules={[
                          {
                            type: 'string',
                            min: 0,
                            max: 64,
                            message: t('feedback_validation_char_range', {
                              min: 0,
                              max: 64,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="size_bytes"
                        label={t('planner_documents_size_bytes')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            type: 'number',
                            message: t('feedback_validation_number'),
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          disabled={isLoading || isSubmitting}
                          style={{ width: 150 }}
                        />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="uploaded_at"
                        label={t('planner_documents_uploaded_at')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                        ]}
                      >
                        <DatePicker
                          showTime
                          disabled={isLoading || isSubmitting}
                          format={Config.get('DEFAULT_DATETIME_FORMAT')}
                        />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name="note"
                        label={t('planner_documents_note')}
                      >
                        <Input.TextArea disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>
                  </Card>
                </Col>

                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t('form_metadata_header')}>
                      <FormMetadata
                        id={id}
                        isSubmitting={isSubmitting}
                        delete={props.delete.bind(this)}
                        deleteRedirectTo="PlannerDocumentsScreen"
                        createdAt={props.createdAt}
                        updatedAt={props.updatedAt}
                      />
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default PlannerDocumentForm;

Logger.log('silly', `PlannerDocumentForm loaded.`);
