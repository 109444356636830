import { useEffect } from 'react';

const useFetchSignedUrl = (
  imagePath,
  imageUrl,
  getSignedUrl,
  setActiveImage
) => {
  useEffect(() => {
    if (imagePath && !imageUrl) {
      getSignedUrl(imagePath, (success, signedUrl) => {
        if (success) {
          setActiveImage(signedUrl, imagePath);
        }
      });
    }
  }, [imagePath, imageUrl, getSignedUrl, setActiveImage]);
};

export default useFetchSignedUrl;
